<script>
import api from '@/helpers/apirest/api';
import Vue from "vue";
export default {
  props: {
    cardApplication: {
      type: Object,
      default: () => {},
    },
  },
  emits:['onCreate', 'print'],
  data() {
    return {
      type: 'youth_card_payment',
    };
  },
  methods: {
    async generate(item) {
        const result = await Vue.swal({
            title: "Generar carta de pago",
            icon: "info",
            html: `Está seguro que desea generar la carta de pago para esta solicitud?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
        });

        if (result.value) {
            await api.post({
                url: `card-applications/${item?.id}/generate-payment-document`,
                config: {
                    withLoading: true,
                },
            });
            this.$emit('onCreate', this.type, item);
        }
    },
    print(item) {
        this.$emit('print', this.type, item);
    }
  }
};
</script>

<template>
  <div>
    <a v-if="!cardApplication?.payment_document_at" href="javascript:void(0);"
        class="mr-3 text-secondary" v-b-tooltip.hover title="Generar carta de pago"
        @click="generate(cardApplication)">
        <i class="mdi mdi-account-cash-outline font-size-18"></i>
    </a>
    <a v-if="cardApplication?.payment_document_at" href="javascript:void(0);"
        class="mr-3 text-success" v-b-tooltip.hover title="Imprimir carta de pago"
        @click="print(cardApplication)">
        <i class="mdi mdi-account-cash font-size-18"></i>
    </a>
  </div>
</template>