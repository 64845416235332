<script>
import api from '@/helpers/apirest/api';
import Vue from "vue";
export default {
  props: {
    cardApplication: {
      type: Object,
      default: () => {},
    },
  },
  emits:['onCreate', 'print'],
  data() {
    return {
      type: 'youth_card',
    };
  },
  methods: {
    async generate(item) {
      const result = await Vue.swal({
          title: "Generar carné",
          icon: "info",
          html: `Está seguro que desea generar el carné para esta solicitud?`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
      });

      if (result.value) {
          await api.post({
              url: `card-applications/${item?.id}/generate-youth-card`,
              config: {
                  withLoading: true,
              },
          });

          this.$emit('onCreate', this.type, item);
      }
    },
    print(item) {
        this.$emit('print', this.type, item);
    }
  }
};
</script>

<template>
  <div>
    <a v-if="!cardApplication?.youth_card_id" href="javascript:void(0);"
        class="mr-3 text-secondary" v-b-tooltip.hover title="generar carné"
        @click="generate(cardApplication)">
        <i class="mdi mdi-card-account-details-outline font-size-18"></i>
    </a>
    <a v-if="cardApplication?.youth_card_id" href="javascript:void(0);"
        class="mr-3 text-success" v-b-tooltip.hover title="Imprimir carné"
        @click="print( cardApplication)">
        <i class="mdi mdi-card-account-details font-size-18"></i>
    </a>
  </div>
</template>