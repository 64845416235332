<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

const initialState = () => {
    return {
        submitted: false,
        card_application: {
            card_application_type_slug: '',
            population: null,
            population_id: null,
            shipping_type: '',
            card_shipping_type: null,
            card_shipping_type_id: null,
            origin_shipping_type: '',
            origin_card_shipping_type: null,
            origin_card_shipping_type_id: null,
            observations: null,
            status: 'processing',
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        customerId: Number,
        cardApplicationTypeSlug: String
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField,
    },
    computed: {
        shippingTypes() {
            return Status.getList('shipping_types');
        }
    },
    validations: {
        card_application: {

            card_application_type_slug: { required },
            population: { required },
            shipping_type: { required },
            origin_shipping_type: { required },
            observations: {  },
            status: { required },
            card_shipping_type: { 
                required(value) {
                    return this.card_application.shipping_type !== 'online' ? !!value : true;
                }
            },
            origin_card_shipping_type: { 
                required(value) {
                    return this.card_application.origin_shipping_type !== 'online' ? !!value : true;
                }
            },
        }
    },
    methods: {
        async shown() {
            this.card_application.card_application_type_slug = this.cardApplicationTypeSlug;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.card_application;
            data.identification_media_id = this.card_application?.identification_media?.id;
            data.population_id = this.card_application?.population?.id;
            data.card_shipping_type_id = this.card_application?.card_shipping_type?.id;
            data.origin_card_shipping_type_id = this.card_application?.origin_card_shipping_type?.id;
            const response = await api.save({
                url: `customers/${this.customerId}/card-applications`,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        changeShippingType() {
            this.card_application.card_shipping_type_id = '';
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de solicitud'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'lg'">
        <div class="card h-100">
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="observations">Motivo</label>
                        <textarea v-model="card_application.observations" class="form-control"
                            :class="{ 'is-invalid': $v.card_application.observations.$error && submitted }">
                        </textarea>
                    </div>

                    <div class="form-group col-12">
                        <label for="origin_shipping_type">Oficina de creación</label>
                        <select v-model="card_application.origin_shipping_type" class="form-control"
                            :class="{ 'is-invalid': $v.card_application.origin_shipping_type.$error && submitted }">
                            <option value="">Ninguno</option>
                            <option v-for="option in shippingTypes" v-bind:value="option.id" :key="option.id">
                                {{ option.description }}
                            </option>
                        </select>
                        <div v-if="!$v.card_application.origin_shipping_type.required && submitted" class="invalid-feedback">Oficina de creación es requerida</div>
                    </div>
                    <div class="form-group col-12" v-if="card_application.origin_shipping_type != '' && card_application.origin_shipping_type != 'online'">
                        <label>Punto de creación</label>
                        <SelectAutocompleteField
                            :url="`/card-shipping-types`"
                            :multiple="false"
                            :placeholder="'Busca una oficina'"
                            v-model="card_application.origin_card_shipping_type"
                            :params="{shipping_type: card_application.origin_shipping_type}"
                            :class="{ 'is-invalid': $v.card_application.origin_card_shipping_type.$error && submitted }"
                        ></SelectAutocompleteField>
                        <div v-if="!$v.card_application.origin_card_shipping_type.required && submitted" class="invalid-feedback">Punto de creación es requerido</div>
                    </div>

                    <div class="form-group col-12">
                        <label for="shipping_type">Oficina de recogida</label>
                        <select v-model="card_application.shipping_type" class="form-control"
                            :class="{ 'is-invalid': $v.card_application.shipping_type.$error && submitted }">
                            <option value="">Ninguno</option>
                            <option v-for="option in shippingTypes" v-bind:value="option.id" :key="option.id">
                                {{ option.description }}
                            </option>
                        </select>
                        <div v-if="!$v.card_application.shipping_type.required && submitted" class="invalid-feedback">Oficina de recogida es requerida</div>
                    </div>
                    <div class="form-group col-12" v-if="card_application.shipping_type != '' && card_application.shipping_type != 'online'">
                        <label>Población de recogida</label>
                        <SelectAutocompleteField
                            :url="`/populations`"
                            :multiple="false"
                            :placeholder="'Busca una población'"
                            :label="'fullname'"
                            v-model="card_application.population"
                            :class="{ 'is-invalid': $v.card_application.population.$error && submitted }"
                        ></SelectAutocompleteField>
                        <div v-if="!$v.card_application.population.required && submitted" class="invalid-feedback">Población es requerida</div>
                    </div>
                    <div class="form-group col-12" v-if="card_application.shipping_type != '' && card_application.shipping_type != 'online' && card_application?.population?.id">
                        <label>Punto de recogida</label>
                        <SelectAutocompleteField
                            :url="`/card-shipping-types`"
                            :multiple="false"
                            :placeholder="'Busca una oficina'"
                            v-model="card_application.card_shipping_type"
                            :params="{shipping_type: card_application.shipping_type, population_id: card_application?.population?.id}"
                            :class="{ 'is-invalid': $v.card_application.card_shipping_type.$error && submitted }"
                        ></SelectAutocompleteField>
                        <div v-if="!$v.card_application.card_shipping_type.required && submitted" class="invalid-feedback">Oficina es requerida</div>
                    </div>
                </div>                         
            </div>
        </div>
    </Modal>
</template>