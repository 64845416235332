<script>
/**
 * Customers Component
 */
export default {
    props: {
      customer: {
            type: Object,
            default: () => {}
        },
    },
    components: {
    },
    data() {
        return {
        };
    },
    mounted(){
    },
    methods: {
    }
};
</script>

<template>
    <div class="row">
      <div class="col-6">
          <div class="card h-100">
              <div class="card-body">                            
                  <h4 class="card-title mb-4">Facturación</h4>

                  <b-skeleton-wrapper :loading="$store.state.loading.loading">
                      <template #loading>
                          <b-card>
                              <b-skeleton width="75%"></b-skeleton>
                              <b-skeleton width="50%"></b-skeleton>
                              <b-skeleton width="85%"></b-skeleton>
                              <b-skeleton width="45%"></b-skeleton>
                              <b-skeleton width="50%"></b-skeleton>
                              <b-skeleton width="60%"></b-skeleton>
                          </b-card>
                      </template>
                      <div class="mb-2">
                          <h6>{{ customer?.billings[0]?.full_name }}</h6>
                          <div v-if="customer?.billings[0]?.company">{{ customer?.billings[0]?.company }}</div>
                          <div v-if="customer?.billings[0]?.address_1">{{ customer?.billings[0]?.address_1 }}</div>
                          <div v-if="customer?.billings[0]?.address_2">{{ customer?.billings[0]?.address_2 }}</div>
                          <div v-if="customer?.billings[0]?.postcode || customer?.billings[0]?.city">{{ customer?.billings[0]?.postcode }} {{ customer?.billings[0]?.city }}</div>
                          <div v-if="customer?.billings[0]?.state">{{ customer?.billings[0]?.state }}</div>
                          <div v-if="customer?.billings[0]?.country">{{ customer?.billings[0]?.country }}</div>
                      </div>
                      <div class="mb-2">
                          <h6>Dirección de correo electrónico:</h6>
                          <a :href="'mailto:' + customer?.billings[0]?.email">{{ customer?.billings[0]?.email }}</a>
                      </div>
                      <div>
                          <h6>Móvil:</h6>
                          <a :href="'tel:' + customer?.billings[0]?.mobile">{{ customer?.billings[0]?.mobile }}</a>
                      </div>
                      <div>
                          <h6>Teléfono:</h6>
                          <a :href="'tel:' + customer?.billings[0]?.phone">{{ customer?.billings[0]?.phone }}</a>
                      </div>
                  </b-skeleton-wrapper>
              </div>
          </div>
      </div>
      <div class="col-6">
          <div class="card h-100">
              <div class="card-body">                            
                  <h4 class="card-title mb-4">Envío</h4>

                  <b-skeleton-wrapper :loading="$store.state.loading.loading">
                      <template #loading>
                          <b-card>
                              <b-skeleton width="75%"></b-skeleton>
                              <b-skeleton width="50%"></b-skeleton>
                              <b-skeleton width="85%"></b-skeleton>
                              <b-skeleton width="30%"></b-skeleton>
                              <b-skeleton width="65%"></b-skeleton>
                          </b-card>
                      </template>
                      <!-- <div class="mb-2" v-if="order?.order_shipping">
                          <div class="mb-2">
                              <h6>{{ order?.order_shipping?.full_name }}</h6>
                              <div v-if="order?.order_shipping?.company">{{ order?.order_shipping?.company }}</div>
                              <div v-if="order?.order_shipping?.address_1">{{ order?.order_shipping?.address_1 }}</div>
                              <div v-if="order?.order_shipping?.address_2">{{ order?.order_shipping?.address_2 }}</div>
                              <div v-if="order?.order_shipping?.postcode || order?.order_shipping?.city">{{ order?.order_shipping?.postcode }} {{ order?.order_shipping?.city }}</div>
                              <div v-if="order?.order_shipping?.state">{{ order?.order_shipping?.state }}</div>
                              <div v-if="order?.order_shipping?.country">{{ order?.order_shipping?.country }}</div>
                          </div>
                          <div>
                              <h6>Teléfono:</h6>
                              <a :href="'tel:' + order?.order_shipping?.phone">{{ order?.order_shipping?.phone }}</a>
                          </div>
                      </div>
                      <div v-else>
                          <i>Sin dirección de envío configurada. Se usará la dirección de facturación.</i>
                      </div> -->
                  </b-skeleton-wrapper>
              </div>
          </div>
      </div>
  </div>
</template>