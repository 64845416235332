<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import OrderShow from "@/views/pages/orders/show/show.vue";
import CardApplicationsPartial from "@/views/pages/customers/show/partials/card-applications-partial.vue";
import OrdersPartial from "@/views/pages/customers/show/partials/orders-partial.vue";
import YouthCardsPartial from "@/views/pages/customers/show/partials/youth-cards-partial.vue";
import CustomerForm from "@/views/pages/customers/forms/customer-form.vue";
import BasicDataPartial from '@/views/pages/customers/show/partials/basic-data-partial.vue';
import HolderDataPartial from '@/views/pages/customers/show/partials/holder-data-partial.vue';

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        cardApplicationId: Number
    },
    data() {
        return {
            customer:null,
            showOrderModalId:'order-show-modal',
            formModalId:'customer-form-detail-modal',
            customerIntegrationStatus: Status,
            showSelectedItemId:null,
            activeTab:null
        }
    },
    components: {
        Modal,
        OrderShow,
        CardApplicationsPartial,
        OrdersPartial,
        YouthCardsPartial,
        CustomerForm,
        BasicDataPartial,
        HolderDataPartial
    },
    computed: {
        modalTilte(){
            return `Detalle del cliente #${(this.customer?.id || '')}`;
        },
        status(){
            return Status.getStatus({itemStatus: this?.customer?.status, type: 'card_application_statuses', html: true});
        },
        gender(){
            return Status.getStatus({itemStatus: this?.customer?.gender, type: 'genders'});
        },
        cardType(){
            return Status.getStatus({itemStatus: this?.customer?.active_youth_card?.card_type, type: 'youth_card_types'});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `customers/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.customer = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        hidden() {
            this.customer = null;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        showFormModal() {
            this.$bvModal.show(this.formModalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" @hidden="hidden" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
            <OrderShow :modal-id="showOrderModalId" :id="showSelectedItemId"></OrderShow>
            <CustomerForm :modal-id="formModalId" :id="customer?.id" @onSuccess="onSuccess"></CustomerForm>
            <div class="row mb-4">
                <div class="col-xl-3 col-lg-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="row mb-4">
                                <div class="col-12 d-flex align-items-center flex-column justify-content-center">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton type="avatar"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>                                            
                                        </template> 

                                        <b-dropdown no-flip right class="qlk-dropdown-sm" style="position: absolute; top: 0px; right: 10px" variant="light" size="sm">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                                            </template>
                                            <b-dropdown-item @click="showFormModal">Editar</b-dropdown-item>
                                        </b-dropdown>

                                        <div class="avatar-sm"><span class="avatar-title text-center text-uppercase rounded-circle bg-soft-primary text-primary">
                                            {{ customer?.first_name?.charAt(0) + customer?.last_name?.charAt(0) }}
                                        </span></div> 
                                        <h4 class="mt-3 mb-0">{{ customer?.name }}</h4>
                                        <p class="text-muted font-size-14 mb-0">{{ customer?.identification_type?.prefix }}: {{ customer?.identification }}</p>
                                    </b-skeleton-wrapper>
                                </div>
                            </div> 
                            <!-- <hr class="my-3" />  -->
                            <div class="row">
                                <div class="col-12">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton-table
                                                :rows="4"
                                                :columns="2"
                                                :hide-header="true"
                                                :table-props="{ bordered: true, striped: true }"
                                            ></b-skeleton-table> 
                                        </template>
                                        <table class="table table-hover mb-0 table-centered">
                                            <tbody>
                                                <tr>
                                                    <th class="text-right nowrap">N° Carné Joven</th>
                                                    <td>{{ customer?.active_youth_card?.code }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Caducidad</th>
                                                    <td>{{ customer?.active_youth_card?.expiration_date }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Modalidad</th>
                                                    <td>{{ cardType }}</td>
                                                </tr>                                                
                                                <tr v-if="customer?.identification_media">
                                                    <th class="text-right nowrap">Adjunto</th>
                                                    <td>
                                                        <a :href="customer?.identification_media?.url" target="_blank">Descargar</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-skeleton-wrapper> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-8">
                    <div class="card h-100">
                        <div class="card-body">
                            <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                                <b-tab class="border-0" v-if="customer?.customer_type === 'holder'">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Datos titulares</span>
                                    </template>
                                    <b-card-text>
                                        <HolderDataPartial :customer="customer"></HolderDataPartial>
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" v-if="customer?.customer_type !== 'holder'">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Datos básicos</span>
                                    </template>
                                    <b-card-text>
                                        <BasicDataPartial :customer="customer"></BasicDataPartial>
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Carné</span>
                                    </template>
                                    <b-card-text>
                                        <YouthCardsPartial :customer="customer"></YouthCardsPartial>
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" :active="(cardApplicationId) ? true : false" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Solicitudes</span>
                                    </template>
                                    <b-card-text>
                                        <CardApplicationsPartial :customerId="id"></CardApplicationsPartial>                                        
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Pedidos</span>
                                    </template>
                                    <b-card-text>
                                        <OrdersPartial :customerId="id"></OrdersPartial>
                                    </b-card-text>
                                </b-tab>                              
                            </b-tabs>                      
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>