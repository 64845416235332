<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";

const initialState = () => {
    return {
        submitted: false,
        status: 'processing',
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        cardApplication: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
    },
    computed: {
        cardApplicationStatuses() {
            return Status.getList('card_application_statuses');
        }
    },
    validations: {
        status: { required },
    },
    methods: {
        async shown() {
            this.status = this.cardApplication?.status;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const response = await api.patch({
                url: `card-applications/${this.cardApplication?.id}/change-status`,
                data: {
                    status: this.status
                },
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de cambio de estado de solicitud'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'lg'">
        <div class="card h-100">
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="shipping_type">Nuevo estado</label>
                        <select v-model="status" class="form-control"
                            :class="{ 'is-invalid': $v.status.$error && submitted }">
                            <option value="">Ninguno</option>
                            <option v-for="option in cardApplicationStatuses" v-bind:value="option.id" :key="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                        <div v-if="!$v.status.required && submitted" class="invalid-feedback">Estado es requerido</div>
                    </div>
                </div>                         
            </div>
        </div>
    </Modal>
</template>