<script>
import CustomTable from "@/components/tables/custom-table.vue";
import OrderShow from "@/views/pages/orders/show/show.vue";
import Status from "@/helpers/general/status";
import TemplatePrint from "@/views/pages/templates/print/template-print.vue";
import YouthCardPaymentDocumentButton from '@/components/buttons/youth-card-payment-document-button.vue';
import YouthCardButton from '@/components/buttons/youth-card-button.vue';
import CardApplicationMovementForm from "@/views/pages/card-applications/forms/card-application-movement-form.vue";
import CardApplicationActions from "@/components/buttons/card-application-actions.vue";

/**
 * Customers Component
 */
export default {
    props: {
        customerId: Number
    },
    components: {
        CustomTable,
        OrderShow,
        TemplatePrint,
        YouthCardPaymentDocumentButton,
        YouthCardButton,
        CardApplicationMovementForm,
        CardApplicationActions
    },
    data() {
        return {
            showOrderModalId: 'card-application-order-show-modal',
            cardApplicationMovementFormId: 'card-application-movement-form-modal',
            templatePrintModalId: 'card-application-template-print-modal',
            customer_status: Status.getList('customers'),
            table: {
                url: '/card-applications',
                params: {
                    'with_trashed': 1,
                    customer_id: this.customerId
                },
                fields: [
                    { key: "id", label: "Cod", sortable: false },                    
                    { key: "created_at", label: "Fecha de solicitud", sortable: false },
                    { key: "reference", label: "N° de referencia", sortable: false },
                    { key: "operation_type", label: "T. operación", sortable: false },
                    { key: "card_application_type.name", label: "T. movimiento", sortable: false },
                    { key: "origin_card_shipping_type.name", label: "Oficina de creación", sortable: false },
                    { key: "card_shipping_type.name", label: "Oficina de envío", sortable: false },
                    { key: "amount", label: "Valor", sortable: false },
                    { key: "order", label: "Pedido", sortable: false },
                    { key: "card_type", label: "Modalidad", sortable: false },                    
                    { key: "status", label: "Estado", sortable: false },
                    { key: "was_paid", label: "Pago", sortable: false },
                ],
                formatted_fields: [
                    'status',
                    'operation_type',
                    'was_paid',
                    'customer.gender',
                    'shipping_type',
                    'card_type',
                    'amount',
                    'order',
                    'reference',
                ]
            },
            formSelectedItemId: null,
            showSelectedOrderId: null,
            selectedTemplatePrintType: null,
            selectedCardApplicationTypeSlug: null,
            selectedTemplatePrintCardApplicationFilters: {},
            clone: false
        };
    },
    methods: {
        showViewModal(item) {
            this.showSelectedOrderId = item?.id || null;
            this.$bvModal.show(this.showOrderModalId);
        },
        showTemplatePrintModal(type, item) {
            this.selectedTemplatePrintType = type;
            this.selectedTemplatePrintCardApplicationFilters = {
                id: item?.id || null
            }
            this.$bvModal.show(this.templatePrintModalId);
        },
        showCardApplicationMovementModal(slug) {
            this.selectedCardApplicationTypeSlug = slug;
            this.$bvModal.show(this.cardApplicationMovementFormId);
        },
        onSuccess() {
            this.$refs.customerCardApplicationTable.refresh();
        },
        onCreateYouthCardTemplate(type, item) {
            this.onSuccess();
            this.showTemplatePrintModal(type, item);
        },
        formatter(field, item) {
            switch (field) {
                case 'customer.gender':
                    return Status.getStatus({ itemStatus: item?.customer?.gender, type: 'genders' });
                case 'shipping_type':
                    return Status.getStatus({ itemStatus: item?.shipping_type, type: 'shipping_types' });
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'card_application_statuses', html: true });
                case 'was_paid':
                    return Status.getStatus({ itemStatus: item?.was_paid, type: 'card_application_paid_status', html: true });
                case 'card_type':
                    return Status.getStatus({ itemStatus: item?.card_type, type: 'youth_card_types', html: true });
                case 'operation_type':
                    return Status.getStatus({ itemStatus: item?.operation_type, type: 'card_application_operation_types', html: true });
                case 'reference':
                    return `${item?.reference || ''} - ${item?.reference_suffix || ''}`;
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <TemplatePrint :type="selectedTemplatePrintType" :modal-id="templatePrintModalId"
                        :card-application-filters="selectedTemplatePrintCardApplicationFilters"></TemplatePrint>
                    <OrderShow :modal-id="showOrderModalId" :id="showSelectedOrderId"></OrderShow>

                    <CardApplicationMovementForm :modal-id="cardApplicationMovementFormId" 
                        :customer-id="customerId" :card-application-type-slug="selectedCardApplicationTypeSlug" @onSuccess="onSuccess"></CardApplicationMovementForm>

                    <div class="d-flex justify-content-end align-items-center">
                        <button class="btn btn-outline-success btn-sm waves-effect waves-light mr-1" @click="showCardApplicationMovementModal('renewal')" title="Añadir solicitud de renovación" v-b-tooltip.hover><i class="mdi mdi-plus"></i> Renovación</button>
                        <button class="btn btn-outline-success btn-sm waves-effect waves-light mr-1" @click="showCardApplicationMovementModal('duplicate')" title="Añadir solicitud de duplicado" v-b-tooltip.hover><i class="mdi mdi-plus"></i> Duplicado</button>
                        <button class="btn btn-outline-success btn-sm waves-effect waves-light mr-1" @click="showCardApplicationMovementModal('refund')" title="Añadir solicitud de devolución" v-b-tooltip.hover><i class="mdi mdi-plus"></i> Devolución</button>
                        <button class="btn btn-outline-success btn-sm waves-effect waves-light mr-1" @click="showCardApplicationMovementModal('loss')" title="Añadir solicitud por extravío" v-b-tooltip.hover><i class="mdi mdi-plus"></i> Extravío</button>
                        <button class="btn btn-outline-success btn-sm waves-effect waves-light" @click="showCardApplicationMovementModal('stolen')" title="Añadir solicitud de por robo" v-b-tooltip.hover><i class="mdi mdi-plus"></i> Robo</button>
                    </div>

                    <CustomTable :url="table.url" :fields="table.fields" :formatted-fields="table.formatted_fields"
                        :formatter="formatter" :params="table.params" ref="customerCardApplicationTable"
                        :show-view="false" :show-edit="false" :showSoftDelete="false">
                        <template #cell-order="{ item }">
                            <a href="javascript:void(0)" v-if="item?.order" @click="showViewModal(item?.order)">{{ item?.order?.id }} ({{
                        item?.order?.external_id }})</a>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell-amount="{ item }">
                            <span>{{ item?.amount | currency }}</span>
                        </template>
                        <template v-slot:after-custom-actions="slotProps">

                            <YouthCardButton :cardApplication="slotProps.row?.item"
                                @onCreate="onCreateYouthCardTemplate"
                                @print="showTemplatePrintModal"
                            ></YouthCardButton>

                            <YouthCardPaymentDocumentButton :cardApplication="slotProps.row?.item"
                                @onCreate="onCreateYouthCardTemplate"
                                @print="showTemplatePrintModal"
                            ></YouthCardPaymentDocumentButton>

                            <CardApplicationActions :card-application="slotProps.row?.item" @onSuccess="onSuccess"></CardApplicationActions>

                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </div>
</template>