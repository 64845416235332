<script>
import { required, minValue, numeric } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";
import EditorPartial from '@/views/pages/templates/forms/partials/editor-partial.vue';
import SettingPartial from '@/views/pages/templates/forms/partials/setting-partial.vue';

const initialState = () => {
    return {
        submitted: false,
        template: {
            name: '',
            type: '',
            content: '',            
            styles: '',     
            settings: {
                margin:{
                    top: '',
                    bottom:'',
                    left:'',
                    right:''
                },
                width:'',
                height:'',
                orientation:''
            }       
        },
        template_fields: [],
        selectedTag: null,
        currentStyles: null,
        editTemplate: false,
        templates: [],
        pdfUrl: null
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        type: String,
        cardApplicationFilters: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return initialState();
    },
    components: {
        Modal,
        EditorPartial,
        SettingPartial
    },
    computed: {
        templateTypes() {
            return Status.getList('template_types');
        }
    },
    validations: {
        template: {
            name: { required },
            type: { required },
            content: { required },    
            settings: {
                margin:{
                    top: { numeric, minValue: minValue(0) },
                    bottom:{ numeric, minValue: minValue(0) },
                    left:{ numeric, minValue: minValue(0) },
                    right:{ numeric, minValue: minValue(0) }
                },
                width:{ numeric, minValue: minValue(0) },
                height:{ numeric, minValue: minValue(0) },
                orientation:{}
            }         
        }
    },
    methods: {
        async shown() {
            this.loadTemplateFields();
            this.changeStyles();
            this.loadTemplates();
        },
        async loadTemplates() {
            const response = await api.get({
                url: `templates`,
                params:{
                    type:this.type
                },
                config: {
                    withLoading: true
                }
            });

            this.templates = response?.data?.data;

            if(this.templates.length === 1){
                this.template = this.templates[0];
                this.changeTemplate();
            }
        },
        async loadTemplateFields() {
            const response = await api.get({
                url: `template-types/${this.type}/fields`,
                config: {
                    withLoading: true
                }
            });
            this.template_fields = response?.data?.data;
        },
        async previewTemplate() {
            const response = await api.post({
                url: `templates/for-card-applications/generate`,
                data: {
                    template: this.template,
                    card_application_filters: this.cardApplicationFilters
                },
                config: {
                    withLoading: true,
                    responseType: 'blob'
                },
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            this.pdfUrl = URL.createObjectURL(blob);

            this.$refs.pdfIframe.src = this.pdfUrl;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.template;
            data.media_id = this.template?.media?.id;
            await api.save({
                url: 'templates',
                id: this.template?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId); 
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },        
        changeTemplate() {
            if(!this.editTemplate){
                this.previewTemplate();
            }
        },
        insertTag(tag) {
            this.selectedTag = tag;
        },
        changeStyles(){
            this.currentStyles = this.template.styles;
        }
    },
};
</script>

<template>
    <Modal :title="'Formulario de Impresión'" :auto-focus="false" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :showConfirmButton="false" :size="'extra-xl'" :label-cancel-btn="'Cerrar'">
        <div class="row">
            <div class="col-lg-2 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="form-group">
                            <label id="template">Plantilla</label>
                            <select v-model="template"                                
                                id="template" 
                                class="form-control"
                                @change="changeTemplate"
                                :class="{ 'is-invalid': $v.template.type.$error && submitted }">
                                <option value="">Ninguno</option>
                                <option v-for="option in templates" v-bind:value="option" :key="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                            <div v-if="!$v.template.type.required && submitted" class="invalid-feedback">Selecciona una plantilla</div>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-outline-secondary btn-sm" @click="editTemplate = !editTemplate">Editar plantilla</button>
                        </div>
                        <SettingPartial v-if="editTemplate" v-model="template.settings" :submitted="submitted" :v="$v"></SettingPartial>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-xs-12 mb-2" v-if="editTemplate">
                <div class="card h-100">
                    <div class="card-body">
                        <b-card no-body class="mb-1 shadow-none">
                            <b-card-header header-tag="header" role="tab">
                            <h4 class="card-title">
                                <a
                                v-b-toggle.accordion-1
                                class="text-dark"
                                href="javascript: void(0);"
                                >Etiquetas dinámicas</a>
                            </h4>
                            </b-card-header>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>
                                        <p class="card-title-desc">
                                            <span v-if="!template.type">
                                                Comienza seleccionando el tipo de plantilla
                                            </span>
                                            <span v-else>
                                                Haz click en la etiqueta para insertarla dentro de la plantilla
                                            </span>
                                        </p>
                                        <div class="list-group">
                                            <a
                                                href="javascript:void(0);"
                                                class="list-group-item list-group-item-action"
                                                v-for="field in template_fields"
                                                :key="field?.key"
                                                title="Click para insertar"
                                                v-b-tooltip.hover
                                                @click="insertTag(field?.key)"
                                            >
                                                {{ field?.key }} ({{ field?.label }})
                                            </a>
                                        </div> 
                                    </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a
                                v-b-toggle.accordion-2
                                class="text-dark"
                                href="javascript: void(0);"
                                >Estilos personalizados</a>
                            </h6>
                            </b-card-header>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <div class="form-group">
                                        <textarea rows="8" v-model="template.styles" @change="changeStyles" class="form-control"></textarea>
                                    </div>
                                </b-card-text>
                            </b-card-body>
                            </b-collapse>
                        </b-card>       
                    </div>
                </div>
            </div>

            <div class="mb-2"
                :class="editTemplate ? 'col-lg-7 col-md-6 col-xs-12' : 'col-lg-10 col-md-6 col-xs-12'"
            >
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab class="border-0" v-if="editTemplate">
                                <template v-slot:title>
                                <span class="d-block">Editor</span>
                                </template>
                                <b-card-text>
                                    <EditorPartial v-if="editTemplate" v-model="template" :v="$v" :submitted="submitted" :tagToInsert="selectedTag" :stylesToApply="currentStyles"></EditorPartial>           
                                </b-card-text>
                            </b-tab>                            
                            <b-tab class="border-0" @click="previewTemplate">
                                <template v-slot:title>
                                <span class="d-block">Imprimir</span>
                                </template>
                                <b-card-text>
                                    <!-- Iframe donde se mostrará el PDF -->
                                    <iframe ref="pdfIframe" style="width: 100%; height: 500px;" v-show="pdfUrl"></iframe>
                                </b-card-text>
                            </b-tab>                            
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>